body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.nav_header ul {
    padding: 0;
    margin: 0;
}

.nav_header ul li {
  list-style: none;
  float: left;
  border-right: 1px solid #1fb91f;
}


.nav_header ul li:first-child{
  border-left: 1px solid #1fb91f;
}

.nav_header ul li:last-child{
  border-right: none;
}


.nav_header img {
  width: 100%;
  height: 40px;
}


.nav_header .left_side_menu a {
  color: #fdfdfd;
  text-decoration: none;
  text-transform: uppercase;
  padding: 19px 0;
  display: block;
  width: 100%;
  text-align: center;
  border-right: 1px solid #10a910;
  font-size: 12px;
}


.nav_header .login_signup {
    background-color: rgb(170, 6, 6);

}

.nav_header .search {
  height: 35px;
}

.fixed {
  position: fixed;
  top: 56px;
  height: 100vh;
  left: 0;
  z-index: 9999999;
  background: #f00;
}

.pading_left{
  padding-left: 21%;
  margin-top:3%;
}

.product-text-center {
  width: 200px;
  margin: 0 5px;

}

.image {
  height: 200px;
  padding: 5px;
  border-radius: 5px;
}

.image img {
    width: 95%;
    height: 95%;
}

.home_page_content {
  margin-top: 30px;
}

.product_wrapper {
    position: relative;
    cursor: pointer;
}

.quantity {
  color: #00ff00;
}


.product_wrapper:hover .cart_view  {
  display: block;
}

.cart_view {
    display:none;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(40,40,40,0.5);
    padding: 1px;
    border-radius: 3px;
}



.icon_cart_plus, .icon_cart_minus {
  font-size: 26px;
  color:#fff;
}

.quantity_section {
  margin: 65px 0px;
}

.price {
  color: #fff;
}

.text_label {
  margin-bottom: 8px;
}

.quantity_common {
  float: left;
  color: #fff;
  padding: 10px 10px;
}

.btn_background_color {
  border-radius: 3px;
}

.cart_minus {
  width: 15%;
  border-right: solid 1px #18cd18;
  cursor: pointer;
}

.cart_plus {
  width: 15%;
  border-left: 1px solid #18cd18;
  cursor: pointer;
}

.quantity_show {
  width: 70%;
}

.details_view {
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0 0 3px 3px;
  border: 1px solid #000;
  border-top: none;
  padding: 3px;
}

.cartList {
    background-color:#fff;
    width: 335px;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 9999;
    box-shadow: rgb(0 0 0) 3px 5px 13px;
}

.cart_product_title, .cart_product_size {
  font-size: 13px;
}

.footer {
  display: none;
  background-color: #bdc0bd;
  width: 100%;
  z-index: 99999;
}

/* About product */

.about-product .title {
    height: 58px;
    overflow: hidden;
    padding: 0;
    margin: 0;
}

/* Cart List Style */
.counter_arrow {
  width: 10%;
  text-align: center;
  float: left;
}

.product_increment{
    display: block;
    float: left;
    width: 100%;
    cursor: pointer;

}
.item_number{
    display: block;
    width: 100%;
}

.product_decrement{
    width: 100%;
    display: block;
    cursor: pointer;

}

.product_decrement:hover, .product_increment:hover{
    background: #228b22;
    color: #fff;
}



.cart_product_image {
  width: 19%;
  float: left;
}

.cart_product_image img {
    width: 100%;
    display: block;
    margin: 5px 0;
}

.product_description {
    width: 43%;
    float: left;
    margin-left: 3px;
}

.product_total_price {
    float: left;
    width: 17%;
    display: block;
    margin-left: 3px;
}

.total_price_remove {
    float: left;
    text-align: center;
    width: 8.6%;
    display: block;
    cursor: pointer;
}

.total_price_remove:hover{
  color: #f00;
}

.icon_quantity:hover {
  color: #228b22;
}


.cart_add_list{
  overflow: auto;
}

.border-bottom {
    width: 100%;
    float: left;
}

.order_button {
    padding: 13px 30px;
}

.order_button a {
    display: block;
    text-decoration: none;
    color: #fff;
    font-weight: bold;
}

.order_button .place_order{
    padding: 8px;
    text-align: center;
    width: 65%;
    float: left;
    display: block;
    background: #f3aa05
}

.order_button .ground_total {
  float: left;
  background: #c49022;
  width: 35%;
  padding: 8px;
  display: block;
  text-align: center;
}

.cart_list_drawer_open{
  margin-right: 0px;
  transition: all .1s;
}

.cart_list_drawer_close{
  margin-right: -100%;
  transition: all 0.1s;
}

.stickyHeader {
  position: fixed;
  top: 45%;
  right: 0;
  text-transform: uppercase;
  text-align: center;
  opacity: 0.7;
  cursor: pointer;
  border: solid 1px #ccc;
  border-right: none;
  z-index: 1;
}

.stickyHeader:hover{
  opacity: 1;
}

.item_count {
  color:#fff;
  background:#000;
}

.item_amount {
  background: #fff;
}

.total_basket {
  width: 100%;
  float: left;
  text-align: center;
  font-size: 24px;
  color: #fff;
}

@keyframes bounce { 
  0% { transform: translateY(0); }
  30% { transform: translateY(-50px); }
  50% { transform: translateY(0); }
  100% { transform: translateY(0); }
}

.bounce {
  animation: bounce 1s;
  animation-timing-function: ease;
  /* animation-iteration-count: infinite; */
}

@media only screen and (max-width: 767.98px) {

  .home_page_content {
    margin-top: 70px;
    margin-bottom: 50px;
  }

  .nav_header .search {
      height: 30px;
  }

  .cart_list_drawer_open{
    margin-right: 0px;
    transition: all .1s;
  }

  .nav_header .left_side_menu a {
      color: #fdfdfd;
      text-decoration: none;
      text-transform: uppercase;
      display: block;
      width: 100%;
      text-align: center;
      border-right: 1px solid #10a910;
      padding: 0;
  }

  .product-text-center {
    width: 162px;
    margin: 0 5px;
  
  }

  .image {
    height: 160px;
    padding: 5px;
    border-radius: 5px;
  }


  .nav_header .left_side_menu {
    border-top: solid 1px #fff;
  }

  .pading_left{
    padding-left: 0%;
    margin-top:3%;
  }


  .footer {
    display: block;
    background-color: #05ca05;
    width: 100%;
    z-index: 99999;
  }

  .order_number {
    position: absolute;
    background: #f00;
    width: 20px;
    height: 20px;
    font-size: 10px;
    color: #fff;
    text-align: center;
    top:-5px;
    left: 8px;
    border-radius: 50%;
  }

  .mobile_bottom_menu span {
    font-size: 20px;
    padding: 0;
    margin: 0;
}

  .mobile_bottom_menu {
    padding: 5px;
    font-size: 20px;
    position: relative;
  }

  .mobile_menu_home {
    position: absolute;
    font-size: 45px;
    top: -9px;
    left:16%;
    border-radius: 50%;
    background-color: #05ca05;
    color: #fff;
    padding: 7px;
}

/* Cart View in mobile */

.order_button {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 0;
    height: 64px;
}

.order_button .place_order, .order_button .ground_total {
  height: 65px;
  padding: 20px;
}

.cartList {
  width: 100%;
  top:0px !important;
  z-index: 999999;
}

.stickyHeader {
  display: none;
}


}